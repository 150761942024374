import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import ContactForm from "../components/contact_form"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
    <section className="page-header">
      <h1>Contact</h1>
    </section>    
    <section className="container flex justify-center items-stretch flex-wrap">
      <div className="service">
        <h2>Openingsuren</h2>
        <ul>
          <li className="my-3"><strong>MA:</strong> gesloten</li>
          <li className="my-3"><strong>DI:</strong> 09:30 - 12:00 | 13:15 - 18:00</li>
          <li className="my-3"><strong>WO:</strong> 09:30 - 12:00 | 13:15 - 18:00</li>
          <li className="my-3"><strong>DO:</strong> 09:30 - 12:00 | 13:15 - 18:00</li>
          <li className="my-3"><strong>VR:</strong> 09:30 - 12:00 | 13:15 - 18:00</li>
          <li className="my-3"><strong>ZA:</strong> 09:30 - 12:00 | 13:15 - 17:00</li>
          <li className="my-3"><strong>ZO:</strong> gesloten</li>
        </ul>
      </div>
      <div className="service">
        <h2>Contactgegevens</h2>
        <p>
          Optiek Tom De Bruyne<br/>
          Vrijheid 184<br/>
          2320 Hoogstraten
        </p>
        <p>
          <a href="tel:+3233146937" className="hover:text-tdb-light">+32(0)3 314 69 37</a><br/>
          info@optiektomdebruyne.be
        </p>
        <p>
          <a href="https://www.instagram.com/optiek_tom_de_bruyne/" target="_blank" rel="noreferrer" className="text-tdb-dark hover:text-tdb-light">
            <svg 
              className="w-4 h-4 fill-current inline-block mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512">
                {/* Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) */}
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
            </svg>
            @optiek_tom_de_bruyne
          </a><br/>
          <a href="https://www.facebook.com/optiektomdebruyne" target="_blank" rel="noreferrer" className="text-tdb-dark hover:text-tdb-light">
          <svg 
            className="w-4 h-4 fill-current inline-block mr-2"
            xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 512 512">
            {/* Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) */}
            <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
          </svg>
          /optiektomdebruyne</a>
        </p>
      </div>
    </section>
    <section className="section">
      <div className="section-content-right">
        {/* <p className="text-base my-4">Wij maken ons ook sterk door u een goede service aan te bieden.  U kunt steeds rekenen op ons deskundig advies en dankzij onze recente investeringen in ons oogmeetapparatuur kunnen wij tot 25x nauwkeurigere metingen uitvoeren en u zo het best mogelijke zicht garanderen.</p> */}
        <h2>Afspraak maken</h2>
        <p>Wenst u graag een afspraak te maken? Vul hieronder uw gegevens in en wij nemen zo snel mogelijk contact met u op.</p>
        <div>
          <ContactForm/>
        </div>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-end">
        <StaticImage
            src="../images/over-ons.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Optiek Tom De Bruyne"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
        {/* <StaticImage
            src="../images/lenzen_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/2"
        /> */}
      </div>
      <div className="section-bg-left"></div>
    </section>
</Layout>
)

export default ContactPage
